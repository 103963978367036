#page {
    color: rgb(162, 196, 136);
    background: rgba(54, 57, 63, 0.884);
    min-width: 100vw;
    min-height:100vh;
}
.div {
    color: rgb(162, 196, 136);
    background: rgba(54, 57, 63, 0.884);
    min-width: 100vw;
    min-height:100vh;
}