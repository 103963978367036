
#home {
    color: rgb(162, 196, 136);
    background: rgba(54, 57, 63, 0.884);
    min-width: 100vw;
    min-height:100vh;
}
#page {
    color: rgb(162, 196, 136);
    background: rgba(54, 57, 63, 0.884);
    min-width: 100vw;
    min-height:100vh;
}
#home-header {
    margin-block-start: 0;
    margin-block-end:0;
    padding-top:.67em;
    text-align: center;
}
#home-paragraph{
    margin-block-start: 0;
    margin-block-end:0;
    padding-top:.67em;
}